const search_goods = document.getElementById('search_goods')
const search_brands = document.getElementById('search_brands')
const search_catalog = document.getElementById('search_catalog')
const search_querys = document.getElementById('search_querys')
const filter = document.getElementById('search_header')
const resiltBody = document.getElementById('result')
const listItems = []

let time;
filter.addEventListener('input', function(e) {
    clearTimeout(time);
    time = setTimeout(() => {
        if (e.target.value == '') {
            search_goods.innerHTML = ''
            search_brands.innerHTML = ''
            search_catalog.innerHTML = ''
            search_querys.innerHTML = ''
            resiltBody.classList.remove('active');
        } else {
            filterData(e.target.value);
            resiltBody.classList.add('active');
        }
        console.log("Задержка 500мс");
    }, "500");
})

function filterData(searchTerm) {
    $.ajax(
        {
            url: '/search_json?format=json&search='+ searchTerm,
            async: true,
            dataType: "json",
            type: 'POST',
            cache: false,
            success: function(result) {
                search_goods.innerHTML = ''
                search_brands.innerHTML = ''
                search_catalog.innerHTML = ''
                search_querys.innerHTML = ''
                //Бренды
                result.results.brands.forEach(brand => {
                    const div = document.createElement('div')
                    listItems.push(div)
                    div.innerHTML = `<div class="brands-one"><a href="${brand.href}">${brand.name}</a></div>`
                    search_brands.appendChild(div);
                });
                //Товары
                result.results.goods.forEach(good => {
                    const div = document.createElement('div')
                    listItems.push(div)
                    div.innerHTML = `<div class="goods-one"><a href="${good.href}">
                                                    <img src="${good.img}">
                                                    <span class="name">${good.name}</span>
                                                    <span class="price"> ${good.price} ₽</span>
                                                  </a></div>`
                    search_goods.appendChild(div);
                });
                //Категория
                result.results.category.forEach(category => {
                    const div = document.createElement('div')
                    listItems.push(div)
                    div.innerHTML = `<div class="category-one"><a href="${category.href}">${category.name}</a></div>`
                    search_catalog.appendChild(div);
                });
                //Популярные запросы
                result.results.querys.forEach(querys => {
                    const div = document.createElement('div')
                    listItems.push(div)
                    div.innerHTML = `<div class="querys-one"><a href="${querys.href}">#${querys.name}</a></div>`
                    search_querys.appendChild(div);
                });
            },
            error: function(res, stat) {
                console.log('Сори, ошибка :(');
            }
        });
    return false;
}
